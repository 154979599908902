﻿@dark1: #1e1e1e;
@dark2: #252526;
@dark3: #2d2d30;
@dark4: #3e3e42;
@dark5: #686868;

@fg1: #c7c7c7;

/* BEGIN global definitions */
body {
	font-family: Calibri, "Trebuchet MS", Verdana, Helvetica, Sans-Serif;
	color: darkgray;
	background-color: black;
}

header,
footer,
nav,
section {
	display: block;
}

/* Styles for basic forms
-----------------------------------------------------------*/

fieldset {
	border: 1px solid #ddd;
	padding: 0 1.4em 1.4em 1.4em;
	margin: 0 0 1.5em 0;
}

legend {
	font-size: 1.2em;
	font-weight: bold;
}

textarea {
	min-height: 75px;
}

.editor-label {
	margin: 1em 0 0 0;
}

.editor-field {
	margin: 0.5em 0 0 0;
}


/* Styles for validation helpers
-----------------------------------------------------------*/
.field-validation-error {
	color: #ff0000;
}

.field-validation-valid {
	display: none;
}

.input-validation-error {
	border: 1px solid #ff0000;
	background-color: #ffeeee;
}

.validation-summary-errors {
	font-weight: bold;
	color: #ff0000;
}

.validation-summary-valid {
	display: none;
}
/* END global definitions */

.right {
	text-align: right;
	white-space: nowrap;
	padding-left: 2px;
	padding-right: 2px;
}

.nowrap {
	white-space: nowrap;
}

.btn-donation {
	-webkit-animation: 5s hue-rotate linear infinite;
}

@-webkit-keyframes hue-rotate {
	0% {
		-webkit-filter: hue-rotate(0deg);
	}
	100% {
		-webkit-filter: hue-rotate(360deg);
	}
}

table.center {
	/*margin-left: auto;
	margin-right: auto;*/
	width: 100%;
}

table .min {
	width: 5em;
}

table.center.table {
	background-color: darkgray;

	thead {
		background-color: #222222;
	}
}

#torrents {
	& > thead > tr > th[data-sort] {
		cursor: pointer;
	}

	a {
		color: #c2bc3a;
	}
}

.one_line {
	overflow: hidden;
	height: 1em;
	line-height: 1em;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 30px;
}

.total {
	background-color: hsl(311, 100%, 20%);
}

tr.tracker table td, tr.peer table td, tr.files table td {
	background-color: #333;
}

.total .title {
	font-weight: bold;
	text-transform: uppercase;
}

div.pourcent {
	margin-top: -1.2em;
	margin-right: 30px;
	text-align: right;
	color: dimgray;
}

body > div.container, body > div#console {
	margin-left: auto;
	margin-right: auto;
	width: 90%;
}

/* BEGIN footer */
div.footer {
	border-top: 1px solid darkgray;
	margin-top: 1em;
	padding-top: 0.5em;
	text-align: center;
}

/* BEGIN logon page */
p.logon_desc {
	text-align: center;
	font-size: 1.5em;
}

#logon {
	& > div {
		text-align: center;
		white-space: nowrap;
	}

	div > div {
		border: 1px solid darkgray;
		line-height: 55px;
		display: inline-block;
		padding-left: 5px;
		padding-right: 5px;
		margin-left: 5px;
		margin-right: 5px;
	}

	img {
		vertical-align: middle;
		cursor: pointer;
	}
}
/* END logon page */

/* BEGIN add torrent form */
input.tooltip {
	font-style: italic;
	color: darkgray;
}

#mtoolbar {
	padding-top: 5px;
	padding-bottom: 5px;
	vertical-align: middle;
	white-space: nowrap;
	height: 42px;
	position: relative;

	& > div:first-child {
		/*top: -1em;
	min-width:779px;
	width: 92%;*/
		margin-right: 152px;
	}

	& > div + div {
		max-width: 147px;
		margin-left: -148px;
		display: inline-block;
		position: relative;
		float: right;
		top: -3.1em;
		/* ff only right: -21em;*/
	}
}

#mtoolbar > div, #toolbar {
	background-color: #222222;
	border-radius: 3px;
	padding: 5px;
	/*margin-top: 5px;
	margin-bottom: 5px;
	min-width: 779px;*/
	/*display: inline-block;
	position: relative;*/
	height: 32px;
}

#toolbar {
	margin-bottom: 0.5em;
}

#uploadTorrent {
	display: inline-block;

	& + form {
		display: inline-block;
		/*float: right;*/
		position: absolute;
		text-align: right;
		right: 155px;
		top: 13px;
		/* ff only top: -1.7em;*/
	}

	input {
		margin-left: 5px;
	}

	span {
		cursor: pointer;
		background-color: #333333;
		border-radius: 3px;
		padding: 2px 5px 2px 5px;
	}

	span.active {
		background-color: hsl(75, 100%, 20%);
	}
}

/*@-moz-document url-prefix() {
	#mtoolbar > div + div {
		right: -21em;
	}

	#uploadTorrent + form {
		top: -1.7em;
	}
}*/
/* END add torrent form */

table#account td {
	background-color: hsl(0, 0%, 20%);
}
